<template>
  <v-card max-width="500">
    <v-toolbar dense dark flat :color="event.tipo.color">
      <v-icon left>
        {{ event.tipo.icon }}
      </v-icon>
      <v-toolbar-title>
        {{ event.tipo.nombre }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="$root.acceso('EDITAR_ESTUDIOS')" @click.stop="deleteEvento" icon small >
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="pa-3">
        <aux-input title="Estudio">
          <div class="d-flex">
            {{ event.idEstudio }}
            <v-spacer></v-spacer>
            <v-btn
              small
              :to="{
                name: 'DetallesEstudio',
                query: { idEstudio: event.idEstudio },
              }"
              rounded
            >
              <v-icon left>mdi-arrow-top-right-thick</v-icon>
              Ver detalles
            </v-btn>
          </div>
        </aux-input>
        <v-divider></v-divider>
        <aux-input title="Nombre">
          {{ event.estudio.nombreProyecto }}
        </aux-input>
        <v-divider></v-divider>
        <aux-input title="Comercial">
          {{ event.estudio.comercial?.nombre }}
        </aux-input>
        <v-divider></v-divider>
        <aux-input title="Estado">
          <status-chip small :value="event?.estudio?.estado_actual?.estado" />
        </aux-input>
        <v-divider></v-divider>
        <template v-if="$root.acceso('EDITAR_ESTUDIOS')" >
          <aux-input title="Estado instalación">
            <status-chip
              small
              :value="event?.estudio?.instalacion?.estado_actual_int?.estado"
            />
          </aux-input>
          <v-divider></v-divider>
        </template>
        <template v-if="$root.acceso('EDITAR_ESTUDIOS')" >
          <aux-input title="Estado cargador">
            <status-chip
              small
              :value="event?.estudio?.cargador?.estado_actual_int?.estado"
            />
          </aux-input>
          <v-divider></v-divider>
        </template>
      <aux-input title="Hora de inicio">
        <datetime-picker
          clear-text="Cancelar"
          ok-text="Aceptar"
          :text-field-props="{
            filled: true,
            hideDetails: 'auto',
            dense: true,
          }"
          :time-picker-props="{ format: '24hr' }"
          v-model="newStart"
        >
        </datetime-picker>
        <template v-if="newStart != event.start">
          <v-btn icon small color="error" @click="newStart = event.start">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            color="primary"
            @click="patchEvento({ start: convertUTCDateToLocalDate(newStart) })"
          >
            <v-icon small>mdi-content-save</v-icon>
          </v-btn>
        </template>
      </aux-input>
      <v-divider></v-divider>
      <aux-input title="Hora de finalización">
        <datetime-picker
          clear-text="Cancelar"
          ok-text="Aceptar"
          :text-field-props="{
            filled: true,
            hideDetails: 'auto',
            dense: true,
          }"
          :time-picker-props="{ format: '24hr' }"
          v-model="newEnd"
        >
        </datetime-picker>
        <template v-if="newEnd != event.end">
          <v-btn icon small color="error" @click="newEnd = event.end">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
          <v-btn
            icon
            small
            color="primary"
            @click="patchEvento({ end: convertUTCDateToLocalDate(newEnd) })"
          >
            <v-icon small>mdi-content-save</v-icon>
          </v-btn>
        </template>
      </aux-input>

      <v-divider class="my-2"></v-divider>

      <v-textarea
        hide-details
        dense
        v-model="newComentario"
        filled
        no-resize
        auto-grow
      />
      <div class="d-flex align-center mt-2" v-if="changedComentario">
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          color="error"
          @click="newComentario = event.comentario"
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>
        <v-btn
          icon
          small
          color="primary"
          @click="patchEvento({ comentario: newComentario })"
        >
          <v-icon small>mdi-content-save</v-icon>
        </v-btn>
      </div>

      <v-divider class="my-2"></v-divider>

      <div class="d-flex align-center">
        <v-btn
          color="error"
          @click="patchEvento({ cancelado: !event.cancelado })"
          :outlined="event.cancelado"
        >
          <v-icon left>mdi-close</v-icon>
          {{ event.cancelado ? "Cancelado" : "Cancelar" }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="patchEvento({ completado: !event.completado })"
          :outlined="event.completado"
        >
          <v-icon left>mdi-check-all</v-icon>
          {{ event.completado ? "Completado" : "Completar" }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { parseDate } from "@/utils";
export default {
  components: {
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
    DatetimePicker: () => import("./DateTimePicker.vue"),
  },
  props: {
    event: Object,
  },
  methods: {
    parseDate,
    isSameDay(d1, d2) {
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    },
    convertUTCDateToLocalDate(date) {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:00`
    },
    async patchEvento(props) {
      await axios({
        url: `${process.env.VUE_APP_API_URL}/calendario_eventos/${this.event.idEvento}`,
        method: "PATCH",
        data: {
          ...props,
        },
      });

      this.$emit("reload");
    },
    async deleteEvento(props) {
      await axios({
        url: `${process.env.VUE_APP_API_URL}/calendario_eventos/${this.event.idEvento}`,
        method: "DELETE",
      });

      this.$emit("reload");
      this.$emit("close");
    },
  },
  data() {
    return {
      newComentario: null,
      newStart: null,
      newEnd: null,
    };
  },
  mounted() {
    this.newComentario = this.event.comentario;
    this.event.start = new Date(this.event.start);
    this.event.end = new Date(this.event.end);
    this.newStart = this.event.start;
    this.newEnd = this.event.end;
  },
  computed: {
    changedComentario() {
      return this.event.comentario != this.newComentario;
    },
    horaInicio() {
      const date = new Date(this.event.start);
      const sameDay = this.isSameDay(new Date(this.event.end), date);
      return ` ${
        !sameDay ? `${date.getDate()}/${date.getMonth() + 1}` : ""
      } ${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")}`;
    },
    horaFin() {
      const date = new Date(this.event.end);
      const sameDay = this.isSameDay(new Date(this.event.start), date);
      return ` ${
        !sameDay ? `${date.getDate()}/${date.getMonth() + 1}` : ""
      } ${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")}`;
    },
  },
};
</script>
